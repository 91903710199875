// src/hooks/useAudioSpectrum.ts
import { useEffect, useRef, useState } from 'react';

interface AudioSpectrumOptions {
  fftSize?: number;   // e.g., 256 or 2048
  updateFps?: number; // frames per second for our data retrieval
  minBin?: number;    // lower bin index (e.g., ~300Hz)
  maxBin?: number;    // upper bin index (e.g., ~3400Hz)
}

interface AudioSpectrumData {
  volume: number;       // overall average volume, if desired
  spectrum: number[];   // slice of frequency bins
}

export function useAudioSpectrum(
  audioNode: AudioNode | undefined,
  {
    fftSize = 256,
    updateFps = 30,
    minBin = 25,
    maxBin = 290, 
  }: AudioSpectrumOptions = {}
): AudioSpectrumData {
  const [data, setData] = useState<AudioSpectrumData>({ volume: 0, spectrum: [] });

  const analyserRef = useRef<AnalyserNode | null>(null);
  const animationIdRef = useRef<number>();
  const lastUpdateRef = useRef<number>(0);

  useEffect(() => {
    if (!audioNode) return;

    const audioContext = audioNode.context as AudioContext;
    const analyser = audioContext.createAnalyser();
    analyser.fftSize = fftSize;

    // Connect our target audio node to the analyser
    audioNode.connect(analyser);
    analyserRef.current = analyser;

    // Prepare array to hold frequency data
    const bufferLength = analyser.frequencyBinCount; 
    const freqData = new Uint8Array(bufferLength);

    const frameInterval = 1000 / updateFps;

    const animate = () => {
      const now = Date.now();
      if (now - lastUpdateRef.current >= frameInterval) {
        analyser.getByteFrequencyData(freqData);

        // 1) Compute “average volume” if you want it
        const avg = freqData.reduce((acc, val) => acc + val, 0) / freqData.length;

        // 2) Slice out the bins corresponding to ~300Hz–3400Hz
        //    Indices 25 & 290 are just approximate for demonstration;
        //    the exact frequency depends on sample rate & fftSize.
        const freqSlice = freqData.slice(minBin, maxBin);

        setData({
          volume: avg,
          spectrum: Array.from(freqSlice), 
        });

        lastUpdateRef.current = now;
      }
      animationIdRef.current = requestAnimationFrame(animate);
    };

    animate();

    // Cleanup on unmount or when audioNode changes
    return () => {
      if (animationIdRef.current) {
        cancelAnimationFrame(animationIdRef.current);
      }
      audioNode.disconnect(analyser);
      analyser.disconnect();
    };
  }, [audioNode, fftSize, updateFps, minBin, maxBin]);

  return data;
}
