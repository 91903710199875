import React, { useMemo } from 'react';
import styled from 'styled-components';

const Container = styled.div<{ $visible: boolean }>`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  gap: 4px;
  width: 30px;
  height: 25px;
  background-color: #f0f0f0;
  border-radius: 6px;
  padding: 4px;
  opacity: ${(props) => (props.$visible ? 1 : 0)};
  transition: opacity 0.3s ease-in-out;
`;

const Bar = styled.div<{ $strength: number }>`
  width: 6px;
  border-radius: 3px;
  background-color: #1371f8;
  height: ${(props) => Math.max(0, Math.min(1, props.$strength)) * 100}%;
  transition: height 0.1s ease-in-out;
`;

export type ThreeBarIndicatorProps = {
  spectrum: number[];
};

export function ThreeBarIndicator({ spectrum }: ThreeBarIndicatorProps) {
  const strengths = useMemo(() => {
    const barsCount = 3;
    const chunkSize = Math.ceil(spectrum.length / barsCount);
    return Array.from({ length: barsCount }).map((_, i) => {
      const slice = spectrum.slice(i * chunkSize, (i + 1) * chunkSize);
      const sum = slice.reduce((acc, val) => acc + val, 0);
      return slice.length ? sum / (slice.length * 255) : 0;
    });
  }, [spectrum]);

  const anyActive = strengths.some((strength) => strength > 0.01);

  return (
    <Container $visible={anyActive}>
      {strengths.map((strength, idx) => (
        <Bar key={idx} $strength={strength} />
      ))}
    </Container>
  );
}

export default ThreeBarIndicator;
