import { Box } from '@mui/material';
import styled from 'styled-components';

const UserAvatarWrapper = styled(Box)`
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background: #d9d9d9;
`;

type UserAvatarProps = {
  children?: React.ReactNode;
  volume?: number;
};

export const UserAvatar = ({children}: UserAvatarProps) => {
  return (
    <UserAvatarWrapper>
      {children}
    </UserAvatarWrapper>
   );
};
