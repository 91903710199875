import { useEffect, useRef, useState } from 'react';
import { Box, Grid, IconButton } from '@mui/material';
import MicNoneOutlinedIcon from '@mui/icons-material/MicNoneOutlined';
import MicOffOutlinedIcon from '@mui/icons-material/MicOffOutlined';
import VideocamOffOutlinedIcon from '@mui/icons-material/VideocamOffOutlined';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import { VolumeMute, VolumeOff } from '@mui/icons-material';
import styled from 'styled-components';
import { useSocket } from '../components/conversations/hooks/useSocket';
import { useSpeechRecognition } from '../components/conversations/speechRecognition';
import { useNavigate, useParams } from 'react-router-dom';
import { Transcript, TranscriptEntry } from '../components/conversations/Transcript';
import { useConversation } from '../components/conversations/hooks/useConversation';
import { useAudioSpectrum } from '../components/conversations/hooks/useAudioSpectrum';
import { BotAvatar } from '../components/conversations/BotAvatar';
import SpeechIndicator from '../components/conversations/ThreeBarIndicator';
import { UserAvatar } from '../components/conversations/UserAvatar';
import AppDrawer from '../components/AppDrawer';

// @ts-ignore: TS just doesn't understand.
import outputWorkletUrl from '../components/conversations/worklets/output.js?url';
//const outputWorkletUrl = new URL('../components/conversations/worklets/output.js?worklet', import.meta.url).href;

console.log('WORKLET', outputWorkletUrl);

const UserContainer = styled(Box)`
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: #8f8f8f;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ControlButton = styled(IconButton)`
  border-radius: 80px !important;
  padding: 10px 20px !important;
  height: 40px;
  background-color: #6a3ef7 !important;
  color: white !important;
  margin: 0 5px !important;
  &:hover {
    background-color: #140048 !important;
  }
  &.Mui-disabled {
    background-color: rgb(204, 204, 204) !important;
    color: rgb(127, 127, 127) !important;
  }
`;

const ControlsContainer = styled(Box)`
  display: flex;
  justify-content: center;
  padding: 12px;
`;

const TranscriptContainer = styled(Box)`
  background: #fff;
  border-radius: 16px;
  height: 100%;
  padding: 1rem;
  overflow: scroll;
`;

type ControlState = {
  microphone: boolean;
  camera: boolean;
  speaker: boolean;
};

type ConversationState = {
  status: 'started' | 'idle'
};

export const Conversation = () => {
  const [transcript, setTranscript] = useState<TranscriptEntry[]>([]);

  const [conversationState, setConversationState] = useState<ConversationState>({
    status: 'idle'
  });

  const navigate = useNavigate();
  const { uuid } = useParams();

  // @ts-ignore: I'll augment process.env with the VITE_SOCKET_URL.
  const { getSocket } = useSocket(import.meta.env.VITE_SOCKET_URL);
 
  const speechRecognition = useSpeechRecognition(2, (transcript: string, isFinal: boolean) => {
    if (isFinal && transcript.length > 0) {
      setTurn('bot');

      setTranscript((prev) => [
        ...prev,
        {
          content: transcript,
          role: 'user'
        }
      ]);

      if (uuid) {
        sendMessage(transcript, uuid);
      } else {
        console.warn('uuid not found');
      }
    }
  });

  const audioContextRef = useRef<AudioContext>();
  const workletNodeRef = useRef<AudioWorkletNode>();
  const gainNodeRef = useRef<GainNode>();

  const {
    status,
    conversation
  } = useConversation(uuid);

  /**
   * Inline effect to track initial conversation load.
   * Loads the conversation's previous responses into the transcript.
   */
  useEffect(() => {
    if (status === 'success') {
      if (conversation && conversation.responses) {
        const transcript = conversation.responses.map(({ statement, isBot }) => ({
          content: statement,
          role: isBot ? 'assistant' : 'user'
        } as TranscriptEntry));

        // Naughty: slice off the last response as it's the current response.
        setTranscript(transcript.slice(0, transcript.length - 1));
      }
    }
  }, [status]);

  useEffect(() => {
    return () => {
      speechRecognition.stop();
    }
  }, [])

  /**
   * Inline effect to handle audio chunks and pass them over to the AudioWorkletNode.
   */
  useEffect(() => {
    const socket = getSocket();
    if (!socket) return;

    const handleAudioChunk = (chunk: ArrayBuffer) => {
      if (workletNodeRef.current) {
        workletNodeRef.current.port.postMessage({ type: 'buffer', buffer: chunk });
      }
    };

    const handleAudioFinal = (reply: { transcript: string }) => {
      setTranscript((prev) => [
        ...prev,
        {
          content: reply.transcript,
          role: 'assistant'
        }
      ]);
    };

    socket.on('audio-chunk', handleAudioChunk);
    socket.on('audio-final', handleAudioFinal);

    return () => {
      socket.off('audio-chunk', handleAudioChunk);
      socket.off('audio-final', handleAudioFinal);
    };
  }, [getSocket]);

  /**
   * Track turns.
   */
  const [turn, setTurn] = useState<'bot' | 'user' | 'idle'>('idle');

  useEffect(() => {
    console.log('turn:', turn);
    if (turn === 'bot') {
      speechRecognition.setEnabled(false);
    } else if (turn === 'user') {
      if (controls.microphone) {
        speechRecognition.setEnabled(true);
      }
    }
  }, [turn]);

  /**
   * Controls state to manage the microphone, camera and speaker.
   */
  const [controls, setControls] = useState<ControlState>({
    microphone: true,
    camera: false,
    speaker: true,
  });

  // Output sampling.
  const { spectrum } = useAudioSpectrum(workletNodeRef.current, {
    fftSize: 2048,
    updateFps: 30,
    minBin: 25,
    maxBin: 128
  });

  // Input sampling.
  // Not possible with Webkit Speech Recognition API, only Whisper.

  const sendMessage = async (question: string, uuid: string) => {
    const socket = getSocket();

    if (socket && socket.connected) {
      socket.emit('user_message', { question, uuid });
    } else {
      console.error('socket is not connected');
    }
  };

  const handleStopRecording = () => {
    speechRecognition.setEnabled(false);
  };

  const handleStartRecording = () => {
    speechRecognition.setEnabled(true);
  };

  const handleSpeakerOn = async () => {
    if (gainNodeRef.current && audioContextRef.current) {
      gainNodeRef.current.gain.setValueAtTime(
        1,
        audioContextRef.current.currentTime
      );
    }
  };

  const handleSpeakerOff = async () => {
    if (gainNodeRef.current && audioContextRef.current) {
      gainNodeRef.current.gain.setValueAtTime(
        0,
        audioContextRef.current.currentTime
      );
    }
  };

  const handleControlClick = (type: keyof ControlState) =>
    () => {
      if (type === 'microphone') {
        if (controls['microphone'] === false) {
          handleStartRecording();
        } else {
          handleStopRecording();
        }
      } else if (type === 'speaker') {
        if (controls['speaker'] === false) {
          handleSpeakerOn();
        } else {
          handleSpeakerOff();
        }
      }

      setControls({
        ...controls,
        [type]: !controls[type]
      })
    };

  /**
   * Start the conversation and creates an AudioWorkletNode.
   * 
   * This only fires once per conversation.
   */
  const handleStartConversation = async () => {
    if (!audioContextRef.current) {
      audioContextRef.current = new AudioContext({ sampleRate: 44100 });
    }

    const audioContext = audioContextRef.current;

    if (audioContext.state === 'suspended') {
      await audioContext.resume();
    }

    // Setup the AudioWorkletNode for output processing.
    await audioContext.audioWorklet.addModule(outputWorkletUrl)

    workletNodeRef.current = new AudioWorkletNode(audioContext, 'output-processor');

    gainNodeRef.current = audioContext.createGain();
    gainNodeRef.current.connect(audioContext.destination);

    workletNodeRef.current.connect(gainNodeRef.current);
    workletNodeRef.current.port.onmessage = ({ data }) => {
      if (data.type === 'process' && data.finished) {
        console.log('audio processing finished, setting turn to user');
        setTurn('user');
      }
    };

    speechRecognition.start();
    setConversationState(prev => ({ ...prev, status: 'started' }));
    setTurn('bot');

    const socket = getSocket();
    if (socket) {
      socket.emit('start_conversation', { uuid });
    }
  };

  /** */
  const handleEndConversation = () => {
    audioContextRef.current?.close();

    navigate(`/conversation-end/${uuid}`);
  };

  if (status === 'loading') {
    return <div>Loading...</div>;
  } else if (status === 'error') {
    return <div>Error loading conversation...</div>;
  }

  return (
    <Grid container height={`calc(100vh - 64px)`} p={4} pt={8} overflow={'hidden'}>
      <Grid item xs={12} md={9} pr={2} minHeight={300} position="relative">
        <UserContainer>
          <UserAvatar />
          <BotAvatar name={conversation?.botName} image={conversation?.botAvatar}>
            <Box position="absolute" bottom={8} right={8}>
              <SpeechIndicator spectrum={spectrum} />
            </Box>
          </BotAvatar>
        </UserContainer>
        <ControlsContainer>
          <ControlButton onClick={handleControlClick('microphone')} disabled={turn !== 'user'}>
            {controls.microphone ? <MicNoneOutlinedIcon /> : <MicOffOutlinedIcon />}
          </ControlButton>
          <ControlButton onClick={handleControlClick('speaker')}>
            {controls.speaker ? <VolumeMute /> : <VolumeOff />}
          </ControlButton>
          <ControlButton disabled>
            <VideocamOffOutlinedIcon />
          </ControlButton>

          {conversationState.status === 'idle' && (
            <ControlButton sx={{
              fontSize: '0.875rem'
            }} onClick={handleStartConversation}>
              <PhoneOutlinedIcon sx={{
                height: '20px',
                marginRight: '5px'
              }} /> Continue
            </ControlButton>
          )}

          {conversationState.status === 'started' && (
            <ControlButton sx={{
              fontSize: '0.875rem'
            }} onClick={handleEndConversation}>
              <PhoneOutlinedIcon sx={{
                height: '20px',
                marginRight: '5px'
              }} /> End Call and Get Analysis
            </ControlButton>
          )}
        </ControlsContainer>
      </Grid>
      <Grid item xs={12} md={3} px={2} height="100%" overflow="hidden">
        <TranscriptContainer>
          <Transcript transcript={transcript} botName={conversation?.botName} />
        </TranscriptContainer>
      </Grid>
    </Grid>
  );
};

export default () => (
  <AppDrawer pageName="Conversation" mainContent={
    <Conversation />
  } />
);
