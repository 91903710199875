// We need to be careful about Gender. Loosely following
// https://www.amnesty.org.uk/LGBTQ-equality/gender-identity-beginners-guide-trans-allies
export enum Gender {
  MALE = 'MALE',              // Cisgender - Male
  FEMALE = 'FEMALE',          // Cisgender - Female
  NON_BINARY = 'NON_BINARY',  // Non-binary
  AGENDER = 'AGENDER',        // Agender - Trans fits under this term
  OTHER = 'OTHER',            // For those who do not not fall into the category above.
  UNSPECIFIED = 'UNSPECIFIED' // For those who do not wish to specify.
}

export const toGender = (g: string): Gender => {
  switch (g.toUpperCase()) {
    case 'M':
    case 'MALE':
      return Gender.MALE;

    case 'F':
    case 'FEMALE':
      return Gender.FEMALE;

    case 'N':
    case 'NON_BINARY':
    case 'NON-BINARY':
      return Gender.NON_BINARY;

    case 'A':
    case 'AGENDER':
    case 'TRANS':
    case 'TRANSEXUAL':
      return Gender.AGENDER;

    case 'O':
    case 'OTHER':
      return Gender.OTHER;

    case 'U':
    case 'UNSPECIFIED':
    default:
      return Gender.UNSPECIFIED;
  }
};

export const toGenderCode = (g: Gender): string => {
  switch (g) {
    case Gender.MALE:
      return 'M';

    case Gender.FEMALE:
      return 'F';

    case Gender.NON_BINARY:
      return 'N';

    case Gender.AGENDER:
      return 'A';

    case Gender.OTHER:
      return 'O';

    case Gender.UNSPECIFIED:
    default:
      return 'U';
  }
};

export const toGenderText = (g: Gender): string => {
  switch (g) {
    case Gender.MALE:
      return 'Male';

    case Gender.FEMALE:
      return 'Female';

    case Gender.NON_BINARY:
      return 'Non-binary';

    case Gender.AGENDER:
      return 'Agender';

    case Gender.OTHER:
      return 'Other';

    case Gender.UNSPECIFIED:
    default:
      return 'Unspecified';
  }
};


// These look like they need to be reviewed.
export enum Ethnicity {
  INDIAN= 'INDIAN',
  BRITISH = 'BRITISH',
  CHINESE = 'CHINESE',
  OTHER_ASIAN = 'OTHER_ASIAN',
  AFRICAN = 'AFRICAN',
  EUROPEAN = 'EUROPEAN',
  LATIN_AMERICAN = 'LATIN_AMERICAN',
  MIDDLE_EASTERN = 'MIDDLE_EASTERN',
  MIXED_ETHNIC = 'MIXED_ETHNIC',
  OTHER_WHITE = 'OTHER_WHITE',
  UNSPECIFIED = 'UNSPECIFIED'
};

export const toEthnicity = (g: string): Ethnicity => {
  switch (g.toUpperCase()) {
    case 'IN':
    case 'INDIAN':
      return Ethnicity.INDIAN;

    case 'BR':
    case 'BRITISH':
      return Ethnicity.BRITISH;

    case 'CH':
    case 'CHINESE':
      return Ethnicity.CHINESE;

    case 'OA':
    case 'OTHER_ASIAN':
    case 'OTHER-ASIAN':
      return Ethnicity.OTHER_ASIAN;

    case 'AF':
    case 'AFRICAN':
      return Ethnicity.AFRICAN;

    case 'EU':
    case 'EUROPEAN':
      return Ethnicity.EUROPEAN;

    case 'LA':
    case 'LATIN-AMERICAN':
      return Ethnicity.LATIN_AMERICAN;

    case 'ME':
    case 'MIDDLE_EASTERN':
    case 'MIDDLE-EASTERN':
      return Ethnicity.MIDDLE_EASTERN;

    case 'MX':
    case 'MIXED_ETHNIC':
    case 'MIXED-ETHNIC':
      return Ethnicity.MIXED_ETHNIC;

    case 'OW':
    case 'OTHER_WHITE':
    case 'OTHER-WHITE':
      return Ethnicity.OTHER_WHITE;

    case 'UN':
    case 'UNSPECIFIED':
    default:
      return Ethnicity.UNSPECIFIED;
  }
};

export const toEthnicityCode = (g: Ethnicity): string => {
  switch (g) {
    case Ethnicity.INDIAN:
      return 'IN';

    case Ethnicity.BRITISH:
      return 'BR';

    case Ethnicity.CHINESE:
      return 'CH';

    case Ethnicity.OTHER_ASIAN:
      return 'OA';

    case Ethnicity.AFRICAN:
      return 'AF';

    case Ethnicity.EUROPEAN:
      return 'EU';

    case Ethnicity.LATIN_AMERICAN:
      return 'LA';

    case Ethnicity.MIDDLE_EASTERN:
      return 'ME';

    case Ethnicity.MIXED_ETHNIC:
      return 'MX';

    case Ethnicity.OTHER_WHITE:
      return 'OW';

    case Ethnicity.UNSPECIFIED:
    default:
      return 'UN';
  }
};

export const toEthnicityText = (g: Ethnicity): string => {
  switch (g) {
    case Ethnicity.INDIAN:
      return 'Indian';

    case Ethnicity.BRITISH:
      return 'British';

    case Ethnicity.CHINESE:
      return 'Chinese';

    case Ethnicity.OTHER_ASIAN:
      return 'Other Asian';

    case Ethnicity.AFRICAN:
      return 'African';

    case Ethnicity.EUROPEAN:
      return 'European';

    case Ethnicity.LATIN_AMERICAN:
      return 'Latin American';

    case Ethnicity.MIDDLE_EASTERN:
      return 'Middle Eastern';

    case Ethnicity.MIXED_ETHNIC:
      return 'Mixed Ethnic Group';

    case Ethnicity.OTHER_WHITE:
      return 'Other White';

    case Ethnicity.UNSPECIFIED:
    default:
      return 'Unspecified';
  }
};


// These look like they need to be reviewed.
export enum Age {
  YOUNG = 'YOUNG',
  MIDDLE_AGED = 'MIDDLE',
  OLDER = 'OLDER'
};

export const toAge = (g: string): Age => {
  switch (g.toUpperCase()) {
    case 'O':
    case 'OLD':
    case 'OLDER':
      return Age.OLDER

    case 'M':
    case 'MA':
    case 'MIDDLE':
    case 'MIDDLE-AGED':
    case 'MIDDLE_AGED':
      return Age.MIDDLE_AGED;

    case 'Y':
    case 'YOUNG':
    default:
      return Age.YOUNG;

  }
};

export const toAgeCode = (g: Age): string => {
  switch (g) {

    case Age.OLDER:
      return 'O';

    case Age.MIDDLE_AGED:
      return 'M';

    default:
    case Age.YOUNG:
      return 'Y';

  }
};
