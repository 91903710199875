import { Ethnicity, Gender } from "./common";
import { ConversationResponseDTO } from "./conversation";
import { SkillDTO } from './skill';

type BaseScenarioAttrs = {
  name: string;
  objective: string;
  scoringMetrics: string[];
  botEmotion: string;
  botPersonality: string;
  botGender: Gender;
  botEthnicity: Ethnicity;
  botAge?: number;
  botJobTitle?: string;
  botEducation?: string;
  botTenureYears?: number;
  botAvatar: string,
  botVoice: string,
  candidateGender: Gender;
  candidateEthnicity: Ethnicity;
  candidateAge?: number;
  candidateTenureYears?: number;
  candidateJobTitle?: string;
  candidateEducation?: string;
};

// Scenario Generation 
export type GeneratedScenarioResponseDTO = ConversationResponseDTO;

export type ScenarioGenerationParamsDTO = BaseScenarioAttrs & {
  candidateName: string;
  botName?: string;
};

export type GeneratedScenarioDTO = BaseScenarioAttrs & {
  brief: string;
  botName: string;
  candidateName: string;
  relationship?: string,
  prologue?: string,
  botInnerThoughts?: string,
  botBackstory?: string,
  candidateBackstory?: string,
  seedResponses?: GeneratedScenarioResponseDTO[];
};

// Scenario (Templates)
export type ScenarioAttrs = BaseScenarioAttrs & {
  brief: string;
  botName: string;
  candidateName: string;
  group?: string;
  privilege: ScenarioPrivilege;
};

export enum ScenarioPrivilege {
  USER = 'U',
  USER_GROUP = 'G',
  PUBLIC = 'P'
};

export type CreateScenarioResponseDTO = ConversationResponseDTO;

export type ScenarioResponseDTO = CreateScenarioResponseDTO & {
  createdAt: Date;
};

export type CreateScenarioDTO = ScenarioAttrs & {
  uuid?: string;
  user?: string;
  skills?: string[];
  seedResponses?: CreateScenarioResponseDTO[];
};

export type UpdateScenarioDTO = ScenarioAttrs & {
  user?: string;
  skills?: string[];
  seedResponses?: CreateScenarioResponseDTO[];
};
export type ScenarioDTO = ScenarioAttrs & {
  uuid: string;
  user: string;
  skills: string[];
  seedResponses?: ScenarioResponseDTO[];
  createdAt: Date;
};

export type ScenarioSearchFilterDTO = {
  users?: string[];
  groups?: string[];
  privilege?: ScenarioPrivilege;
};

export type ScenarioSummaryDTO = ScenarioAttrs & {
  skills: string[];
  uuid: string;
  user: string;
  createdAt: Date;
};

export type ScenarioGroupDTO = {
  uuid: string;
  label: string;
  scenarios: ScenarioSummaryDTO[];
};

export type ScenariosDTO = {
  skills: SkillDTO[];
  my?: ScenarioSummaryDTO[];
  groups?: ScenarioGroupDTO[];
  public: ScenarioSummaryDTO[];
};
