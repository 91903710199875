import { UserDetailsDTO } from "./types/auth";
import { ConversationDTO, ConversationResponseDTO, ConversationSkillScoreDTO, ConversationSortBy, ConversationSummaryDTO } from "./types/conversation";
import { CourseDTO } from "./types/course";
import { CharacterDTO, MasterDataDTO, PersonasDTO } from "./types/master-data";
import { ScenarioDTO, ScenarioGroupDTO, ScenariosDTO, ScenarioSummaryDTO } from './types/scenario';
import { SkillDTO } from "./types/skill";
import { ServerSettingsDTO } from "./types/types";

export const toUserDetails = (data: any): UserDetailsDTO => {
  return Object.assign({}, data, {
    licenseAcceptedAt: new Date(data.licenseAcceptedAt),
    birthYear: Number.parseInt(data.birthYear, 10),
    yearsAtCompany: Number.parseInt(data.yearsAtCompany, 10),
    lastModifiedAt: data.lastModifiedAt ? new Date(data.lastModifiedAt) : undefined,
  });
};

export const toSkillDTO = (data: any): SkillDTO => {
  return Object.assign({}, data, {
    createdAt: new Date(data.createdAt),
    lastModifiedAt: data.lastModifiedAt ? new Date(data.lastModifiedAt) : undefined,
  });
};

export const toCourseDTO = (data: any): CourseDTO => {
  return Object.assign({}, data, {
    createdAt: new Date(data.createdAt),
    lastModifiedAt: data.lastModifiedAt ? new Date(data.lastModifiedAt) : undefined,
  });
};

export const toPersonasDTO = (data: any): PersonasDTO => {
  return data as PersonasDTO;
}

export const toCharacterDTO = (data: any): CharacterDTO => {
  return data as CharacterDTO;
};

export const toServerSettingsDTO = (data: any): ServerSettingsDTO => {
  return data as ServerSettingsDTO;
};

export const toMasterDataDTO = (data: any): MasterDataDTO => {
  return {
    skills: data.skills.map(toSkillDTO),
    scenarios: data.scenarios.map(toScenarioDTO),
    courses: data.courses.map(toCourseDTO),
  };
};

export const toScenarioDTO = (data: any): ScenarioDTO => {
  return Object.assign({}, data, {
    createdAt: new Date(data.createdAt),
    lastModifiedAt: data.lastModifiedAt ? new Date(data.lastModifiedAt) : undefined,
  });
};

export const toScenarioSummaryDTO = (data: any): ScenarioSummaryDTO => {
  return Object.assign({}, data, {
    createdAt: new Date(data.createdAt)
  });
};

export const toScenarioSummariesDTO = (summaries: any[]): ScenarioSummaryDTO[] => {
  return summaries.map((s: any) => toScenarioSummaryDTO(s));
};

export const toScenarioGroupDTO = (data: any): ScenarioGroupDTO => {
  return Object.assign({}, data);
};

export const toScenarioGroupsDTO = (groups: any[]): ScenarioGroupDTO[] => {
  return groups.map((s: any) => toScenarioGroupDTO(s));
};

export const toScenariosDTO = (data: any): ScenariosDTO => {
  return Object.assign({}, data, {
    skills: data.skills ? data.skills.map(toSkillDTO) : [],
    my: data.my ? data.my.map(toScenarioSummaryDTO) : [],
    groups: data.groups ? toScenarioGroupsDTO(data.groups) : { },
    public: data.public.map(toScenarioSummaryDTO),
  });
};

export const toConversationResponseDTO = (data: any): ConversationResponseDTO => {
  return Object.assign({}, data, {
    createdAt: new Date(data.createdAt),
    lastModifiedAt: data.lastModifiedAt ? new Date(data.lastModifiedAt) : undefined,
  });
};

export const toConversationSkillScoreDTO = (data: any): ConversationSkillScoreDTO => {
  return Object.assign({}, data, {
    createdAt: new Date(data.createdAt),
  });
};

export const toConversationDTO = (data: any): ConversationDTO => {
  return Object.assign({}, data, {
    responses: data.responses.map(toConversationResponseDTO),
    skills: data.skills.map(toConversationSkillScoreDTO),
    createdAt: new Date(data.createdAt),
    lastModifiedAt: data.lastModifiedAt ? new Date(data.lastModifiedAt) : undefined
  });
};

export const toConversationSummaryDTO = (data: any): ConversationSummaryDTO => {
  return Object.assign({}, data, {
    createdAt: new Date(data.createdAt),
    lastModifiedAt: data.lastModifiedAt ? new Date(data.lastModifiedAt) : undefined,
  });
};

export const toConversationSummariesDTO = (summaries: any[], sortBy?: ConversationSortBy): ConversationSummaryDTO[] => {
  const dtoSummaries = summaries.map((s: any) => toConversationSummaryDTO(s));
  switch (sortBy) {
    case ConversationSortBy.CREATED_AT_ASC:
      return dtoSummaries.sort((a, b) => a.createdAt.getTime() - b.createdAt.getTime());

    case ConversationSortBy.CREATED_AT_DESC:
      return dtoSummaries.sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime());
  }

  return dtoSummaries;
};
