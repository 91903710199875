import { useEffect, useState } from 'react';

import AppDrawer from '../components/AppDrawer';
import api from '../api';
import { ProfileForm } from '../components/user/ProfileForm';
import Box from '@mui/material/Box';
import { UserDetailsDTO } from '../api/types/auth';
import { ProfileFormData } from '../components/user/schema/user';
import { Typography } from '@mui/material';
import AutoFadeAlert from '../components/AutoFadeAlert';

const Settings = () => {
  const [profile, setProfile] = useState<UserDetailsDTO | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  const fetchUserProfile = async () => {
    setLoading(true)
    try {
      const data = await api.getMyDetails();
      setProfile(data);
    } catch (err) {
      console.error(err);
    } finally {
      setTimeout(() => setLoading(false), 2000);
    }
  };

  const handleProfileSubmit = async (data: ProfileFormData) => {
    const updatedProfile = {
      ...profile,
      ...data
    } as UserDetailsDTO;

    setSuccess(false);
    setLoading(true);
    try {
      const data = await api.updateMyDetails(updatedProfile)
      setProfile(data);
      setSuccess(true);
    } catch (err) {
      console.error(err);
    } finally {
      setTimeout(() => setLoading(false), 2000);
    }
  };

  useEffect(() => {
    fetchUserProfile();
  }, []);

  const mainContent = (
    <>
      {success && (
        <AutoFadeAlert message="Your profile has been updated successfully." />
      )}

      {profile && (
        <Box sx={{ mt: 4, px: 5, py: 4, maxWidth: '500px' }}>
          <Box sx={{ mb: '2rem' }}>
            <Typography variant="h6" sx={{
              mb: '0.5rem'
            }}>
              Edit My Profile
            </Typography>
            <Typography variant="body2">
              Please fill the form below so that we can build a better conversation experience.
            </Typography>
          </Box>
          <ProfileForm
            onSubmit={handleProfileSubmit}
            defaultValues={profile as ProfileFormData}
          />
        </Box>
      )}
    </>
  );

  return (
    <AppDrawer pageName="Settings" mainContent={mainContent} />
  );
};

export default Settings;
