export type FeatureMap = {[key:string]: boolean};

export type UserEnabledFeatures = {
  system: FeatureMap;
  groups: {[key:string]: FeatureMap};
};

export enum Feature {
  VIEW_REPORT_SUMMARY = 'view-report-summary',
  VIEW_REPORT_DETAILS = 'view-report-details',
  ANONYMOUS_CONVERSATIONS = 'anonymous-conversations',
  DYNAMIC_SCENARIOS = 'dynamic-scenarios',
  EDIT_DYNAMIC_SCENARIOS = 'edit-dynamic-scenarios',
  STT_MODEL_SELECTION = 'stt-model-selection',
  GENERATE_CONVERSATION = 'generate-conversation',
  USE_STREAMING_AUDIO = 'use-streaming-audio',
  PROMOTE_SCENARIOS = 'promote-scenarios',
  EXPORT_SCENARIOS = 'export-scenarios'
};

export const hasFeature = (feature: Feature, userFeatures: UserEnabledFeatures, groupUuid?: string): boolean => {
  if (groupUuid) {
    if (userFeatures.groups[groupUuid] && userFeatures.groups[groupUuid][feature] !== undefined) {
      return userFeatures.groups[groupUuid][feature];
    }
  }

  if (userFeatures.system[feature] !== undefined) {
    return userFeatures.system[feature];
  }

  return false;
};

export const hasFeatureInAny = (feature: Feature, features: UserEnabledFeatures): boolean => {
  if (features.system[feature]) {
    return true;
  } else {
    for (const groupKey of Object.keys(features.groups)) {
      if (features.groups[groupKey][feature]) {
        return true;
      }
    }
  }

  return false;
};
