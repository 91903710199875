import { useState, useEffect } from 'react';
import { Alert, Fade } from '@mui/material';
import Check from '@mui/icons-material/Check';

interface AutoFadeAlertProps {
  message: string;
  severity?: 'success' | 'info' | 'warning' | 'error';
  duration?: number; // Duration in milliseconds
  icon?: React.ReactNode;
  sx?: object;
}

import CheckIcon from '@mui/icons-material/Check';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import ErrorIcon from '@mui/icons-material/Error';

const severityDefaults = {
  success: {
    icon: <CheckIcon fontSize="inherit" htmlColor="#fff" />,
    sx: {
      backgroundColor: '#17b26a', // Green
      color: '#fff',
    },
  },
  info: {
    icon: <InfoIcon fontSize="inherit" htmlColor="#fff" />,
    sx: {
      backgroundColor: '#2196f3', // Blue
      color: '#fff',
    },
  },
  warning: {
    icon: <WarningIcon fontSize="inherit" htmlColor="#fff" />,
    sx: {
      backgroundColor: '#ff9800', // Orange
      color: '#fff',
    },
  },
  error: {
    icon: <ErrorIcon fontSize="inherit" htmlColor="#fff" />,
    sx: {
      backgroundColor: '#f44336', // Red
      color: '#fff',
    },
  },
};

const AutoFadeAlert: React.FC<AutoFadeAlertProps> = ({
  message,
  severity = 'success',
  duration = 3000,
  icon,
}) => {
  const [visible, setVisible] = useState<boolean>(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false);
    }, duration);
    return () => clearTimeout(timer);
  }, [duration]);

  // Get default styles and icon based on severity
  const defaults = severityDefaults[severity];

  return (
    <Fade in={visible} unmountOnExit>
      <Alert
        icon={icon || defaults.icon}
        severity={severity}
        sx={{
          position: 'absolute',
          width: '100%',
          borderRadius: '0',
          pl: '2.5rem',
          ...defaults.sx,
        }}
      >
        {message}
      </Alert>
    </Fade>
  );
};

export default AutoFadeAlert;
