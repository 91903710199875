import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect } from "react";
import { Box, Button, Grid, InputLabel, MenuItem } from "@mui/material";
import { Ethnicity, Gender, toEthnicity, toEthnicityCode, toEthnicityText, toGender, toGenderCode, toGenderText, } from '../../api/types/common';
import { getEthnicities, getEthnicity, getGender, getGenders, getPersonalities, getPersonality } from '../../lib/masterdata';
import { profileSchema, type ProfileFormData } from "./schema/user";
import TextField from "../form/TextField";
import Select from "../form/Select";

type ProfileFormProps = {
  onSubmit: (data: ProfileFormData) => void;
  defaultValues?: ProfileFormData;
};

export const ProfileForm = ({
  onSubmit,
  defaultValues
}: ProfileFormProps) => {
  const { register, handleSubmit, reset, control, formState: { errors } } = useForm<ProfileFormData>({
    resolver: zodResolver(profileSchema),
    defaultValues,
  });

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  return (
    <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <InputLabel htmlFor="birthYear">Birth Year</InputLabel>
          <TextField
            {...register("birthYear")}
            fullWidth
            type="number"
            error={!!errors.birthYear}
            helperText={errors.birthYear?.message}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputLabel htmlFor="gender">Gender</InputLabel>
          <Controller
            name="gender"
            control={control}
            defaultValue={defaultValues?.gender || Gender.MALE}
            render={({ field }) => (
              <Select
                {...field}
                fullWidth
                required
              >
                {getGenders().map((g: Gender) => (
                  <MenuItem key={toGenderCode(g)} value={g}>{toGenderText(g)}</MenuItem>
                ))}
              </Select>
            )}
          />
        </Grid>
        <Grid item sm={12}>
          <InputLabel htmlFor="ethnicity">Ethnicity</InputLabel>
          <Controller
            name="ethnicity"
            control={control}
            defaultValue={defaultValues?.ethnicity || Ethnicity.BRITISH}
            render={({ field }) => (
              <Select
                {...field}
                fullWidth
                required

              >
                {getEthnicities().map((e: Ethnicity) => (
                  <MenuItem key={toEthnicityCode(e)} value={e}>{toEthnicityText(e)}</MenuItem>
                ))}
              </Select>
            )}
          />
        </Grid>
        <Grid item sm={12}>
          <InputLabel htmlFor="highestEducation">Education Level</InputLabel>
          <TextField {...register("highestEducation")} 
            fullWidth 
            error={!!errors.highestEducation}
            helperText={errors.highestEducation?.message} />
        </Grid>
        <Grid item sm={12}>
          <InputLabel htmlFor="company">Company</InputLabel>
          <TextField {...register("company")} 
            fullWidth 
            error={!!errors.company}
            helperText={errors.company?.message} />
        </Grid>
        <Grid item sm={12}>
          <InputLabel htmlFor="jobTitle">Job Title</InputLabel>
          <TextField {...register("jobTitle")} 
            fullWidth 
            error={!!errors.jobTitle}
            helperText={errors.jobTitle?.message} />
        </Grid>
        <Grid item sm={12}>
          <InputLabel htmlFor="yearsAtCompany">Years at Company</InputLabel>
          <TextField {...register("yearsAtCompany")} 
            fullWidth 
            error={!!errors.yearsAtCompany}
            helperText={errors.yearsAtCompany?.message} type="number" />
        </Grid>
        <Grid item sm={12}>
          <InputLabel htmlFor="personality">Personality</InputLabel>
          <Controller
            name="personality"
            control={control}
            defaultValue={defaultValues?.personality || 'Extroverted'}
            render={({ field }) => (
              <Select
                {...field}
                fullWidth
                required
              >
                {getPersonalities().map((p) => (
                  <MenuItem key={p} value={p}>{p}</MenuItem>
                ))}
              </Select>
            )}
          />
        </Grid>
      </Grid>
      <Button
        type="submit"
        fullWidth
        variant="contained"
        sx={{ height: '48px', mt: '2rem' }}
      >
        Save My Profile
      </Button>
    </Box>
  );
};
