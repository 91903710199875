import { styled } from '@mui/material/styles';
import MUI_Select from '@mui/material/Select';

const Select = styled(MUI_Select)(
  () => ({
    '& .MuiInputBase-input': {
      padding: '9px 14px',
    }
  })
);

export default Select;
