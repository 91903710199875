import React, { useState } from 'react';
import { Box, Button, FormControl, Grid, MenuItem, Typography } from '@mui/material';
import { ScenarioDTO } from '../api/types/scenario';
import api from '../api';
import { RootState } from '../store';
import { useSelector } from 'react-redux';
import { UserGroupSummaryDTO } from '../api/types/auth';
import { Feature, hasFeature } from '../lib/features';
import Select from './form/Select';

export const PromoteScenario = ({ scenarioUuid, onSave, onCancel }: {
  scenarioUuid: string,
  onSave: (scenarioUuid: string, groupUuid: string) => void,
  onCancel: () => void
}) => {
  const [scenario, setScenario] = useState<ScenarioDTO>();
  const [groups, setGroups] = useState<UserGroupSummaryDTO[]>();
  const [group, setGroup] = useState<string>();
  const auth = useSelector((state: RootState) => state.auth);

  React.useEffect(() => {
    api.getScenario(scenarioUuid).then((s) => {
      setScenario(s);
    });
    api.getMyGroups().then((groups) => {
      const promotableScenarios = Object.keys(auth.features.groups).reduce((acc, groupKey) => {
        if (hasFeature(Feature.PROMOTE_SCENARIOS, auth.features, groupKey)) {
          acc.add(groupKey);
        }
        return acc;
      }, new Set<string>());

      const allowedGroups = groups.filter((g) => promotableScenarios.has(g.uuid));
      if (allowedGroups.length > 0) {
        setGroups(allowedGroups);
        setGroup(allowedGroups[0].uuid);
      }
    });
  }, [auth]);

  return (
    <>
      <Typography id="modal-title" variant="h6" component="h2">
        Promote '{scenario?.name}' scenario to
      </Typography>

      {group && groups && groups.length > 0 && (
        <Grid container sx={{ mt: 2 }}>
          <FormControl fullWidth margin="normal">
            <Select
              value={group}
              variant="outlined"
              onChange={(e) => setGroup(e.target.value as string) }
            >
              {groups.map((g: UserGroupSummaryDTO) => (
                <MenuItem key={g.uuid} value={g.uuid}>{g.description}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      )}

      {(groups === undefined || groups.length === 0) && (
        <Grid container sx={{ mt: 2 }}>
          Sorry, you do not have permissions to promote the scenario to any group.
        </Grid>
      )}

      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item xs={12} md={6}>
          <Box display="flex" justifyContent="flex-start">
            <Button
              onClick={() => onCancel()}
              variant="outlined"
              color="primary"
            >
              Cancel
            </Button>
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box display="flex" justifyContent="flex-end">
            {(groups && groups.length > 0) && (<Button
                onClick={() => {
                  (scenario && group) ? onSave(scenarioUuid, group) : onCancel();
                }}
                variant="contained"
                color="primary"
              >
                Confirm
              </Button>
            )}
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default Navigator;
