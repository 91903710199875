// src/components/BotAvatar.tsx
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

const BotAvatarWrapper = styled.div`
  width: 100px;
  height: 100px;
  transition: transform 0.1s ease-out; 
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 20px;
  right: 35px;
`;

const BotImage = styled.img`
  width: 100px;
  height: 100px;
  object-fit: cover;
  border: 4px solid #e9e9e9;
  border-radius: 8px;
  position: relative;
`

type BotAvatarProps = {
  children: React.ReactNode;
  image?: string;
  name?: string;
};

export const BotAvatar = ({ image, name, children }: BotAvatarProps) => {
  const avatarRef = useRef<HTMLDivElement>(null);

  return (
    <BotAvatarWrapper ref={avatarRef}>
      <>
        {image && (
          <BotImage alt="Bot Avatar" src={image} />
        )}
        {children}
      </>
    </BotAvatarWrapper>
  );
};
