import * as z from "zod";

export const profileSchema = z.object({
  birthYear: z.coerce.number().int()
    .min(new Date().getFullYear() - 100)
    .max(new Date().getFullYear()),

  company: z.string().min(1, { 
    message: "Company is required" 
  }),

  ethnicity: z.string().min(1, { 
    message: "Ethnicity is required" 
  }),

  gender: z.string().min(1, {
    message: "Gender is required" 
  }),

  personality: z.string().min(1, { 
    message: "Personality is required" 
  }),
  
  highestEducation: z.string().min(1, {
    message: "Education level is required" 
  }),
  
  jobTitle: z.string().min(1, { 
    message: "Job title is required" 
  }),
  
  yearsAtCompany: z.coerce.number().int().min(0),
});

export const signupSchema = z.object({
  username: z.string().min(1, { 
    message: "Username is required" 
  }),

  firstName: z.string().min(1, { 
    message: "First name is required" 
  }),

  lastName: z.string().min(1, { 
    message: "Last name is required" 
  }),

  email: z.string().email({ 
    message: "Email is required" 
  }),

  password: z.string().min(1, { 
    message: "Password is required" 
  }),

  passwordAgain: z.string().min(1, { 
    message: "Password is required" 
  }),
});

export type SignUpFormData = z.infer<typeof signupSchema>;
export type ProfileFormData = z.infer<typeof profileSchema>;
