import { useCallback, useEffect, useRef, useState } from "react";
import { io, type Socket } from "socket.io-client";

export const useSocket = (url: string) => {
  const socketRef = useRef<Socket>();

  useEffect(() => {
    socketRef.current = io(url);

    socketRef.current.on('connect', () => {
      console.log('connected to server');
    });

    socketRef.current.on('disconnect', () => {
      console.log('disconnected from server');
    });

    socketRef.current.on('error', (error) => {
      console.error('error:', error);
    });

    return () => {
      socketRef.current?.off('connect');
      socketRef.current?.off('disconnect');
      socketRef.current?.off('error');
      socketRef.current?.disconnect();
    }
  }, []);

  const getSocket = useCallback(() => socketRef.current, []);

  return {
    getSocket
  }
}
