import * as React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { useNavigate } from 'react-router-dom';

import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';

import { ScenarioSummaryDTO } from '../api/types/scenario';
import api from '../api';
import { trackEvent } from '../lib/tracking';
import Cookies from 'js-cookie';
import { ProgressIndicator } from './ProgressIndicator';
import { SignUp } from '../pages/SignUp';
import { useEffect } from 'react';
import { UserDetailsDTO } from '../api/types/auth';
import { ProfileForm } from './ProfileForm';
import { getPersonality } from '../lib/masterdata';

type StartConversationDialogProps = {
  scenario: ScenarioSummaryDTO;
};

const isProfileComplete = (user: UserDetailsDTO | undefined) => {
  if (!user) return false;

  const required = [
    'birthYear',
    'company',
    'ethnicity',
    'gender',
    'personality',
    'highestEducation',
    'jobTitle',
    'yearsAtCompany',
  ];

  // @ts-ignore: Fix later. Not important.
  return required.every(key => !!user[key]);
}

const StartConversationDialog: React.FC<StartConversationDialogProps> = ({ scenario }) => {
  const [openSignup, setOpenSignup] = React.useState(false);
  const [openProfile, setOpenProfile] = React.useState(false);
  const [profile, setProfile] = React.useState<UserDetailsDTO>();
  const [loading, setLoading] = React.useState(false);

  const loggedIn = Cookies.get('isAuthenticated') === 'true';
  const navigate = useNavigate();
  const features = useSelector((state: RootState) => state.auth.features);

  const startConversation = async (profile: UserDetailsDTO) => {
    setLoading(true);
    
    const conversation = await api.createConversationFromScenario({
      candidateName: profile.firstName + ' ' + profile.lastName,
      candidatePersonality: getPersonality(profile.personality),
      candidateGender: scenario.candidateGender,
      candidateEthnicity: scenario.candidateEthnicity,
    }, scenario.uuid);

    setLoading(false);
    
    navigate(`/conversation/${conversation.uuid}/`);
  };

  const handleClickOpen = async () => {
    trackEvent('Button', 'Click', 'Start the Conversation');
    
    if (loggedIn) {
      if (!isProfileComplete(profile)) {
        setOpenProfile(true);
      } else {
        if (profile) {
          startConversation(profile);
        }
      }
    } else {
      await api.ping('join');
      setOpenSignup(true);
    }
  };

  const handleProfileSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const form = Object.fromEntries(new FormData(e.currentTarget));

    if (profile) {
      const updatedProfile = {
        ...profile,
        ...form,
      };

      setProfile(updatedProfile);

      if (isProfileComplete(updatedProfile)) {
        try {
          await api.updateMyDetails(updatedProfile);
          setOpenProfile(false);
          startConversation(updatedProfile);
        } catch (err) {
          console.error(err);
        }
      } else {
        // Handle incomplete profile, e.g., show an error message
      }
    }
  };

  const handleSignupClose: DialogProps["onClose"] = (event, reason) => {
   // if (reason && reason === "backdropClick")return;
    setOpenSignup(false);
  };

  const handleProfileClose: DialogProps["onClose"] = (event, reason) => {
    //if (reason && reason === "backdropClick")return;
    setOpenProfile(false);
  };

  useEffect(() => {
    if (!loggedIn) return;
    
    const getUserProfile = async () => {
      try {
        const profile = await api.getMyDetails();
        
        setProfile(profile);
      } catch (err) {
        console.error(err);
      }
    };

    getUserProfile();
  }, [loggedIn]);

  return (
    <>
      {loading && (
        <ProgressIndicator projectedTimeMs={28000} intervalMs={500} isComplete={!loading} message='We are finalising the conversation...' />
      )}
      <Dialog
        open={openSignup}
        onClose={handleSignupClose}
        PaperProps={{
          sx: {
            width: '500px',
            height: 'auto',
            maxHeight: '720px'
          },
        }}
        disableEscapeKeyDown
      >
        <Box sx={{ p: 2 }}>
          <DialogContent>
            <SignUp onSuccess={(form) => {
              setOpenSignup(false);

              // @ts-ignore: Fix later.
              startConversation(form);
            }}/>
          </DialogContent>
        </Box>
      </Dialog>
      <Dialog
        open={openProfile}
        onClose={handleProfileClose}
        PaperProps={{
          sx: {
            width: '500px',
            height: 'auto',
            maxHeight: '720px'
          },
        }}
        disableEscapeKeyDown
      >
        <Box sx={{ p: 2 }}>
          <DialogContent>
            <ProfileForm onSubmit={handleProfileSubmit} profile={profile} />
          </DialogContent>
        </Box>
      </Dialog>
      <Box sx={{ m: 2 }}>
        <Button
          sx={{
            width: {
              xs: '100%',
              sm: 'auto',
            },
          }}
          variant="contained"
          onClick={handleClickOpen}>
          Join Conversation
        </Button>
      </Box>
    </>
  );
};

export default StartConversationDialog;
