import { useForm } from "react-hook-form";
import { signupSchema, type SignUpFormData } from "./schema/user";
import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Button, Grid, InputLabel, Link, Typography } from "@mui/material";
import TextField from "../form/TextField";
import { useNavigate, useParams } from "react-router-dom";
import { buildConvPassthruUrl, isComplexPassword } from '../../lib/utilities';

type SignUpFormProps = {
  submitLabel?: string,
  onSubmit: (data: SignUpFormData) => void;
};

export const SignUpForm = ({
  onSubmit,
  submitLabel = 'Create Account'
}: SignUpFormProps) => {
  const { register, handleSubmit, reset, control, formState: { errors } } = useForm<SignUpFormData>({
    resolver: zodResolver(signupSchema),
  });

  const navigate = useNavigate();
  
  const { conversationUuid = '', correlationUuid = undefined } = useParams();

  const handleLoginClick = () => {
    navigate(buildConvPassthruUrl('/login', conversationUuid, correlationUuid), { state: { next: `/report/${conversationUuid}` } });
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box component="form" autoComplete="off" noValidate onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <InputLabel htmlFor="username">Username</InputLabel>
            <TextField
              {...register("username")}
              required
              fullWidth
              autoFocus
              autoComplete="off"
              error={!!errors.username}
              helperText={errors.username?.message}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputLabel htmlFor="firstName">First Name</InputLabel>
            <TextField
              {...register("firstName")}
              required
              fullWidth
              error={!!errors.firstName}
              helperText={errors.firstName?.message}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputLabel htmlFor="lastName">Last Name</InputLabel>
            <TextField
              {...register("lastName")}
              required
              fullWidth
              error={!!errors.lastName}
              helperText={errors.lastName?.message}
            />
          </Grid>
          <Grid item sm={12}>
            <InputLabel htmlFor="email">Email</InputLabel>
            <TextField
              {...register("email")}
              required
              fullWidth
              error={!!errors.email}
              helperText={errors.email?.message}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputLabel htmlFor="password">Password</InputLabel>
            <TextField
              {...register("password")}
              required
              fullWidth
              type="password"
              autoComplete="off"
              error={!!errors.password}
              helperText={errors.password?.message}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputLabel htmlFor="passwordAgain">Confirm Password</InputLabel>
            <TextField
              {...register("passwordAgain")}
              required
              fullWidth
              type="password"
              autoComplete="off"
              error={!!errors.passwordAgain}
              helperText={errors.passwordAgain?.message}
            />
          </Grid>
        </Grid>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ height: '48px', my: '2rem' }}
        >
          {submitLabel}
        </Button>
        <Grid container justifyContent="center">
          <Grid item>
            <Link
            component="span"
            onClick={handleLoginClick}
            style={{ cursor: 'pointer', textDecoration: 'underline' }}
            variant="body2"
          >
            Already have an account? Log in
          </Link>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
