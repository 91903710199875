import * as React from 'react';
import { useState, useRef, useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TermsContent from '../components/TermsContent';
import { trackEvent } from '../lib/tracking';
import { Button } from '@mui/material';

interface ModalProps {
  open: boolean;
  handleClose: () => void;
  handleAgreeTerms: () => void;
}

const TermsAndConditionsModal: React.FC<ModalProps> = ({ open, handleClose, handleAgreeTerms }) => {
  const contentRef = useRef<HTMLDivElement>(null);

  const handleAgreeAndRegister = () => {
    trackEvent('Button', 'Click', 'Agree to T&C');
    handleAgreeTerms();
    handleClose();
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          padding: '2rem',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '720px',
          bgcolor: 'background.paper',
          boxShadow: 24,
          maxHeight: '80vh',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box sx={{ p: 0 }}>
          <Typography variant="h5" gutterBottom sx={{ fontFamily: 'inter, sans-serif', p: '1rem 0', mb: 0 }}>
            Welcome to Mindbloom!
          </Typography>
          <Typography variant="h6" gutterBottom sx={{ fontFamily: 'inter, sans-serif' }}>
            To use Mindbloom please review and accept our Terms and Conditions.
          </Typography>
        </Box>
        <Box
          ref={contentRef}
          sx={{ 
            flexGrow: 1, 
            overflowY: 'auto', 
            pl: 0, 
            border: '1px solid #ccc',          
            borderRadius: '4px' 
          }}
        >
          <TermsContent />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: 'background.paper',
          }}
        >
          <Button
            type="submit"
            variant="contained"
            sx={{ height: '48px', mt: '2rem', w: '200px' }}
            onClick={handleAgreeAndRegister}
          >
            I Agree
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default TermsAndConditionsModal;
