import { styled } from '@mui/material/styles';
import MUI_TextField from '@mui/material/TextField';

/**
 * The joys of MUI. 
 * This is a styled TextField component that has padding adjusted.
 */
const TextField = styled(MUI_TextField)(
  () => ({
    '.MuiInputBase-input ': {
      padding: '10px 14px',
    }
  })
);

export default TextField;
