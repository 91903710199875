import React, { useEffect, useState } from 'react';
import ChromeNoAirpodsAlert from './ChromeNoAirpodsAlert';
import UnsupportedBrowserAlert from './UnsupportedBrowserAlert';
import Conversation from '../pages/Conversation';
import ConversationWithStream from '../pages/ConversationWithStream';
import Scenarios from '../pages/Scenarios';
import { isSpeechToTextSupported } from '../audio/speech-to-text';
import { Feature, hasFeature } from '../lib/features';
import { useSelector } from 'react-redux';
import { RootState } from '../store';


const ConversationCheckBrowserWrapper = () => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  useEffect(() => {
    if (isChrome() || !isSpeechToTextSupported() || !isSupportedBrowser()) {
      setSnackbarOpen(true);
    }
  }, []);

  const features = useSelector((state: RootState) => state.auth.features);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const isChrome = () => {
    const userAgent = navigator.userAgent;
    return /Chrome/.test(userAgent) && /Google Inc/.test(navigator.vendor);
  };

  const isSupportedBrowser = () => {
    const userAgent = navigator.userAgent;
    const isSafari = /Safari/.test(userAgent) && /Apple Computer/.test(navigator.vendor);
    const isEdge = /Edg/.test(userAgent);

    return isChrome() || isSafari || isEdge;
  };

  const ConversationComponent = Conversation;

  // There was actually no point doing this since I toggle it in the environment variables by service.
  // const ConversationComponent = hasFeature(Feature.USE_STREAMING_AUDIO, features)
  //   ? ConversationWithStream
  //   : Conversation
  // ;

  if (!isSpeechToTextSupported()) {
    return (
      <>
        <UnsupportedBrowserAlert open={snackbarOpen} handleClose={handleSnackbarClose} severity="error" message="Speech recognition is not supported in this browser. Please use the latest version of Chrome, Safari, or Edge." />
        <Scenarios />
      </>
    );
  } else if (!isSupportedBrowser()) {
    return (
      <>
        <UnsupportedBrowserAlert open={snackbarOpen} handleClose={handleSnackbarClose} severity="warning" message="Browser not supported. Please use the latest version of Chrome, Safari, or Edge." />
        <ConversationComponent />
      </>
    );
  } else {
    if (isChrome()) {
      return (
        <>
          <ChromeNoAirpodsAlert open={snackbarOpen} handleClose={handleSnackbarClose} />
          <ConversationComponent />
        </>
      );
    } else {
      return <ConversationComponent />;
    }
  }
};

export default ConversationCheckBrowserWrapper;
