import { useEffect, useState } from "react";
import api from "../../../api";
import { ConversationDTO } from "../../../api/types/conversation";
import { set } from "lodash";

type ConversationState = {
  status: 'idle' | 'loading' | 'success' | 'error';
  conversation: ConversationDTO | null;
};

export const useConversation = (uuid: string | undefined) => {
  const [state, setState] = useState<ConversationState>({
    status: 'idle',
    conversation: null
  });

  useEffect(() => {
    const getConversation = async (uuid: string) => {
      setState({ status: 'loading', conversation: null });
      
      try {
        const res = await api.getConversation(uuid);      
        setState({ status: 'success', conversation: res });
      } catch (err) {
        setState({ status: 'error', conversation: null });
      }
    }

    if (uuid) {
      getConversation(uuid);
    };
  }, [uuid]);

  return state;
};
